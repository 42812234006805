import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function FootLine() {

    const { t } = useTranslation(['footline']);
    return(
        <Container fluid className="pagefooter">
            <Container fluid className='line'>
                <a href='/blog'>{t('project_genesis.footline.cities')}</a>
                <a href='/imprint' >{t('project_genesis.footline.imprint')}</a>
                <a href='/dataprotection'>{t('project_genesis.footline.data')}</a>
                <a href='/agb'>{t('project_genesis.footline.codeofconduct')}</a>
            </Container>
            <Container fluid className='line'>
                <span>© 2024</span>
                <a href='https://www.genesis-adventures.de'>www.genesis-adventures.de</a>
                <span>{t('project_genesis.footline.rights')}</span>
            </Container>
        </Container>
    );
}

export default FootLine;