import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { isMobile } from 'react-device-detect';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

function SideScroller(props) {

  var classes = "";
  if (props.classList !== undefined) {
    classes = " " + props.classList;
  }

  var autoPlay = true;
  if (props.autoPlay === false) {
    autoPlay = false;
  }

  return (
      <Carousel
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlay={autoPlay}
          autoPlaySpeed={20000}
          removeArrowOnDeviceType={["mobile", "tablet"]}
          deviceType={isMobile ? "mobile" : "computer"}
          className={'sidescroller' + classes}
          afterChange={props.afterChange}
          >
              {props.children}
          </Carousel>
  )
}

export default SideScroller;