function clamp(num, min, max) {
    return Math.max(min, Math.min(max, num))
}

export const options = {duration: 15000, easing: "linear", iterations: 1, fill: "forwards"};
const posClamp = 2.5;
const rotClmap = 4;
const deviation = 1.25;
const rotdeviation = 10;
const mag = 0.3;
const rotmag = 7;

export function step(pos, vel, mul=1, withMouse=true) {
    pos = [pos[0]+vel[0], pos[1]+vel[1], pos[2]+vel[2], pos[3]+vel[3]];
    let forceBack = [
        pos[0] / deviation,
        pos[1] / deviation,
        pos[2] / rotdeviation,
        pos[3] / rotdeviation
    ];
    vel = [vel[0]-forceBack[0]*Math.random()*mag, vel[1]-forceBack[1]*Math.random()*mag,
            vel[2]-forceBack[2]*Math.random()*mag*rotmag, vel[3]-forceBack[3]*Math.random()*mag*rotmag];
    vel = [clamp(vel[0], -posClamp, posClamp), clamp(vel[1], -posClamp, posClamp), clamp(vel[2], -rotClmap, rotClmap), clamp(vel[3], -rotClmap, rotClmap)];
    let keyframe;
    if (withMouse) {
        keyframe = {
            transform: `translate(${pos[0]/10*mul}pc, ${pos[1]/20*mul}pc) rotateX(calc(${pos[2]/5*mul}deg + var(--posY) * -10deg)) rotateY(calc(${pos[3]/1.25*mul}deg + var(--posX) * 10deg))`
        };
    } else {
        keyframe = {
            transform: `translate(${pos[0]/10*mul}pc, ${pos[1]/20*mul}pc) rotateX(${pos[2]/5*mul}deg) rotateY(${pos[3]/1.25*mul}deg)`
        };
    }
    return [keyframe, pos, vel];
}

export function createArrays() {
    return [[0, 0, 0, 0], [posClamp - Math.random()*posClamp*2, posClamp - Math.random()*posClamp*2, rotClmap - Math.random()*rotClmap*2, rotClmap - Math.random()*rotClmap*2]];
}