import React, { useEffect, useRef, useState } from 'react';import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import ArrowLeft from './subcomponent/GetStarted/ArrowLeft';
import ArrowRight from './subcomponent/GetStarted/ArrowRight';
import background from '../assets/img/Hintergrund7_16_9.png';
import minbackground from '../assets/img/minimized/Hintergrund7_16_9.png';
import Headline from './subcomponent/Headline';
import { useDispatch, useSelector } from 'react-redux';
import Section from './Section';
import { setShowingThemeIndex } from '../redux/slices/TourPlannerSlice';
import AnimCard, { toCoverLinks } from './subcomponent/GetStarted/AnimCard';
import { createArrays, options, step } from './subcomponent/GetStarted/Keyframer';

function switchAnimCards(card, newCard, newTheme, firstAnimStep) {
    newCard.style.width = `${card.offsetWidth}px`;
    newCard.classList.remove("hidden");
    card.getAnimations().forEach(a => a.cancel());
    card.style.transform = firstAnimStep.transform[0];
    card.style.opacity = firstAnimStep.opacity[0];
    var links = toCoverLinks(newTheme.key);
    card.children[0].src = links[0];
    card.children[1].src = links[1];
}
function cleanPreparedAnim(card) {
    card.style.transform = '';
    card.style.opacity = '';
}
function cleanUp(newCard) {
    newCard.style.width = '';
    newCard.classList.add("hidden");
    let parent = newCard.parentNode;
    parent.style.height = null;
}

let data = [];
function resetArrays() {
  data = createArrays();
}

function AdventureShowcase() {

    const themeIndex = useSelector((state) => state.tourPlanner.showingThemeIndex);
    const themes = useSelector((state) => state.tourPlannerCategories.themes);

    const animOptions = {duration: 500, iterations: 1, easing: "ease-in-out"};
    const { t } = useTranslation(['adventure_showcase']);
    const dispatch = useDispatch();

    const startPlanning = () => {
        window.location.hash = "";
        window.location.hash = "#a-tourplanner";
    };

    const [Content, setContent] = useState();

    useEffect(() => {
        var textElement = document.getElementById("showcase-text");
        const theme = themes[themeIndex];
        if (theme !== undefined) {
            if (Content === undefined) {
                createDescription(theme.key);
            }
            textElement.classList.remove("hide");
        }
    }, [themes, themeIndex]);

    const createDescription = (key, withButton=true) => {
        fetch(`${window.env.PUBLIC_URL}/covers/${key}.txt`).then((data) => data.text().then((text) => {
            let content;
            if (text == "!SOON") {
                content = (<div><h2>{t('project_genesis.wip_a')}</h2><h3>{t('project_genesis.wip_b')}</h3></div>);
            } else if (text.startsWith("<!DOCTYPE html>")) {
                content = (
                    <div>
                        <error>{t('project_genesis.loading_error')}</error>
                    </div>
                );
            } else {
                var tokens = text.split("///");
                if (tokens.length == 2) {
                    content = (
                        <div>
                            <h1>{tokens[0]}</h1>
                            <p>{tokens[1]}</p>
                            { withButton && <Button onClick={startPlanning} className='showcase_button'>{t('project_genesis.plan_tour_now')}</Button> }
                        </div>
                    );
                } else {
                    content = (
                        <div>
                            <p>{text}</p>
                            { withButton && <Button onClick={startPlanning} className='showcase_button'>{t('project_genesis.plan_tour_now')}</Button> }
                        </div>
                    );
                }
            }
            setContent(content);
        })).catch((e) => {
            let fallback = (
                <div>
                    <p>text</p>
                    { withButton && <Button onClick={startPlanning} className='showcase_button'>{t('project_genesis.plan_tour_now')}</Button> }
                </div>
            );
            setContent(fallback);
        });
    };

    const newTextBlend = (key) => {
        var textElement = document.getElementById("showcase-text");
        textElement.ontransitionend = (ev) => {
            textElement.ontransitionend = null;
            createDescription(key);
        }
        textElement.classList.add("hide");
    };

    let transitioning = 0;

    const decIndex = (e) => {
        if (transitioning === 0) {
            transitioning = -1;
            let themeindex = themeIndex;
            var currentCard = document.getElementById("currentShowcaseAnimCard");
            if (themeindex < 1) {
                themeindex = themes.length-1;
            } else {
                --themeindex;
            }
            var currentkeyframes = {
                opacity: [0, 1],
                transform: ["translateX(125%)", "translateX(0%)"],
                filter: ["brightness(0)", "brightness(1)"]
            };
            var nextkeyframes = {
                opacity: [1, 0],
                transform: ["translateX(0%) translateZ(200px)", "translateX(-125%) translateZ(200px)"],
                filter: ["brightness(1)", "brightness(0)"]
            };
            var nextCard = document.getElementById("nextShowcaseAnimCard");
            var nextTheme = themes[themeindex];
            currentCard.firstChild.onload = () => {
                currentCard.firstChild.onload = null;
                nextCard.animate(nextkeyframes, animOptions);
                newTextBlend(nextTheme.key);
                let animation = currentCard.animate(currentkeyframes, animOptions);
                cleanPreparedAnim(currentCard);
                animation.onfinish = () => {
                    dispatch(setShowingThemeIndex(themeindex));
                    cleanUp(nextCard);
                };
            };
            switchAnimCards(currentCard, nextCard, nextTheme, currentkeyframes);
        }
      };
      const incIndex = (e) => {
        if (transitioning === 0) {
            transitioning = 1;
            let themeindex = themeIndex;
            var currentCard = document.getElementById("currentShowcaseAnimCard");
            if (themes.length-1 <= themeindex) {
                themeindex = 0;
            } else {
                ++themeindex;
            }
            var currentkeyframes = {
                opacity: [0, 1],
                transform: ["translateX(-125%)", "translateX(0%)"],
                filter: ["brightness(0)", "brightness(1)"]
            };
            var nextkeyframes = {
                opacity: [1, 0],
                transform: ["translateX(0%) translateZ(200px)", "translateX(125%) translateZ(200px)"],
                filter: ["brightness(1)", "brightness(0)"]
            };
            var nextCard = document.getElementById("nextShowcaseAnimCard");
            var nextTheme = themes[themeindex];
            currentCard.firstChild.onload = () => {
                currentCard.firstChild.onload = null;
                nextCard.animate(nextkeyframes, animOptions);
                newTextBlend(nextTheme.key);
                let animation = currentCard.animate(currentkeyframes, animOptions);
                cleanPreparedAnim(currentCard);
                animation.onfinish = () => {
                    dispatch(setShowingThemeIndex(themeindex));
                    cleanUp(nextCard);
                };
            };
            switchAnimCards(currentCard, nextCard, nextTheme, currentkeyframes);
        }
      };

    const changeMousePosition = (e) => {
        var currentCard = document.getElementById("currentShowcaseAnimCard");
        var root = document.querySelector(":root");
        root.style.setProperty('--posX', -1+((e.clientX)/window.innerWidth)*2);
        root.style.setProperty('--posY', -1+((e.clientY+currentCard.getBoundingClientRect().top/4)/window.innerHeight)*2);
    };

    const makeKeyframes = (mul) => {
        let keyframes = [];
        let keyframe;
        let [pos, vel] = data;
        for (let i = 0; i < 100; ++i) {
            [keyframe, pos, vel] = step(pos, vel, mul);
            keyframes.push(keyframe);
        }
        data = [pos, vel];
        return keyframes;
      }

    const animate = (c, mul=1) => {
        let anim = c.animate(makeKeyframes(mul), options);
        anim.onfinish = () => {
            animate(c, mul);
        };
    };

    useEffect(() => {
        resetArrays();
        let card = document.getElementById("currentShowcaseAnimCard");
        if (card) {
            animate(card, 0.6);
        }
    }, [themeIndex]);

    return(
        <Section id="a-adventure-showcase" minbg={minbackground} bg={background} mouseMove={changeMousePosition}>
            <Headline classList="headline-text-shadow" text={t('project_genesis.headline')} />
            <div className='showcaseAnimations'>
                <ArrowLeft onClick={decIndex}></ArrowLeft>
                <div className='animCardsHolder'>
                    <AnimCard id="currentShowcaseAnimCard" theme={themes[themeIndex]}></AnimCard>
                    <AnimCard id="nextShowcaseAnimCard" theme={themes[themeIndex]} className='fadeOut hidden'></AnimCard>
                </div>
                <div id="showcase-text" className='showcase-text'>
                    {Content}
                </div>
                <ArrowRight onClick={incIndex}></ArrowRight>
            </div>
        </Section>
    );
}

export default AdventureShowcase;