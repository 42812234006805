import React, { useState } from 'react';
import { Container, Image } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import bg from '../../assets/img/Impressum_Hintergrund_dunkel.png';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import FootLine from '../../component/FootLine';
import Burger from '../../component/subcomponent/Burger'
import MenuBar from '../../component/MenuBar';

import './blog.scss';

function Article(props) {

    const { article } = useParams();
    const prefix = `${window.env.PUBLIC_URL}/blogs`;
    const { t } = useTranslation(['blog']);

    const [res, loadRes] = useState(null);
    const [text, loadText] = useState("");


    if (res === null) {
        fetch(`${prefix}/list.json`)
        .then((res) => {
        if (res.ok) {
            res.json()
            .then((data) => {
            loadRes(data.articles.find((o) => o.res === article));
            });
        } else {
            throw new Error("Failed to load successfully");
        }
        })
        .catch((e) => {
        console.error(e);
        });
    } else if (text === "") {
        fetch(`${prefix}/articles/${res.res}.txt`)
        .then((res) => {
        if (res.ok) {
            res.text()
            .then((data) => {
            loadText(data);
            });
        } else {
            throw new Error("Failed to load successfully");
        }
        })
        .catch((e) => {
        console.error(e);
        });
    }

    return(
        <main>
            <I18nextProvider i18n={i18n}>
                <Burger subpage />
                <MenuBar subpage />
                <Image fluid src={bg} className='blog-bg' />
                <Container fluid className='position-absolute top-0 left-0 ps-0 pe-0 blog-content-filler'>
                    <div className='article'>
                        <h4 className='blog-headline'>{t('our_blog')}</h4>
                        <img src={`${prefix}/covers/${article}.png`} />
                        <div className='text'>
                        {
                            res !== null && <h1>{res.headline}</h1>
                        }
                        {
                            <p>{text}</p>
                        }
                        </div>
                    </div>
                    <FootLine />
                </Container>
            </I18nextProvider>
        </main>
    )

}

export default Article;