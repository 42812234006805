import React from 'react';

function BulletTip(props) {

    if (props.number > 3) {
        return(
            <div id={"b-" + props.number} className='howto-tooltips howtoreverseTooltip'>
                <p className='title'>{props.title}</p>
                <p>{props.text}</p>
                <img src={props.gif} alt="Gif" />
            </div>
        );
    }

    return(
        <div id={"b-" + props.number} className='howto-tooltips howtotooltip'>
            <p className='title'>{props.title}</p>
            <p>{props.text}</p>
            <img src={props.gif} alt="Gif" />
        </div>
    );
}

export default BulletTip;