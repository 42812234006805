import React from 'react';
import { Container, Image } from "react-bootstrap";
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import FootLine from '../../component/FootLine';

import Burger from '../../component/subcomponent/Burger'
import MenuBar from '../../component/MenuBar';

// Style
import './agb.scss';


// Images
import bg from '../../assets/img/Impressum_Hintergrund_dunkel.png';
import res_bg from '../../assets/img/responsive_backgrounds/Background6_Responsive.png';
import { isMobile } from 'react-device-detect';


function AGB() {

  const { t } = useTranslation(['agb']);

  const f = (text) => {
    let index = text.indexOf("///");
    if (index !== -1) {
      let title = text.substring(0, index);
      let description = text.substring(index+3);
      return [(<h6 key={title}>{title}</h6>), (<p key={`p-${title}`} className='description'>{description}</p>)];
    }
    return (<p key={`p-${text.substring(0, 9)}`} className='description'>{text}</p>);
  }

  const ulf = (text) => {
    let points = text.split("///");
    return (
      <ul>
        {
        Array.from(points, (point, i) => 
          <li key={i}>{point}</li>
        )
        }
      </ul>
    );
  }

  return (
    <main>
      <I18nextProvider i18n={i18n}>
        <Burger subpage />
        <MenuBar subpage />
        <Container fluid className='position-relative ps-0 pe-0'>
          <Image fluid src={(isMobile) ? res_bg : bg} className='agb-bg'/>
          <div className='agb-holder'>
            <div className='text'>
              {
                !isMobile ? (<h4>{t('headline')}</h4>) : (<h4 className='agb-headline'>{t('headline')}</h4>)
              }
              <p className={ isMobile ? 'description spacing' : 'description'}>{t('headline_under_a')}</p>
              <a href='https://www.genesis-adventures.de/'>https://www.genesis-adventures.de/</a>
              <p className='headline_under'>{t('headline_under_b')}</p>
              <a href='mailto:office@genesis-adventures.de'>office@genesis-adventures.de</a>
              <p className='headline_under'>{t('headline_under_c')}</p>
              {f(t('scope'))}
              {f(t('service'))}
              {f(t('bookings'))}
              {f(t('gift_cards'))}
              {f(t('freedom_event'))}
              {f(t('youth_protection'))}
              {f(t('withdrawal'))}
              {f(t('compensation.a'))}
              {ulf(t('compensation.b_list'))}
              {f(t('compensation.c'))}
              {f(t('behaviour'))}
              {f(t('clause'))}
              {f(t('online-dispute-resolution.a'))}
              <span><a href='https://ec.europa.eu/consumers/odr' target='_blank'>https://ec.europa.eu/consumers/odr</a>.</span>
              {f(t('online-dispute-resolution.b'))}
            </div>
          </div>
          <FootLine />      
        </Container>
      </I18nextProvider>
    </main>
  );
}

export default AGB;