import { createSlice } from "@reduxjs/toolkit";

export const tpBookingSlice = createSlice({
  name: "booking",
  initialState: {
    orderState: "ORDER_INIT",

    shippingGender: "male",
    shippingFirstName: "",
    shippingLastName: "",
    shippingAdress: "",
    shippingNumber: "",
    shippingAdditional: "",
    shippingPostalCode: "",
    shippingCity: "",

    company: "",
    taxId: "",
    billingIsShipping: true,

    billingGender: "male",
    billingFirstName: "",
    billingLastName: "",
    billingAdress: "",
    billingNumber: "",
    billingAdditional: "",
    billingPostalCode: "",
    billingCity: "",
  },
  reducers: {
    setOrderState(state, d) {
      state.orderState = d.payload;
    },

    setShippingGender(state, d) {
      state.shippingGender = d.payload;
    },
    setShippingFirstName(state, d) {
      state.shippingFirstName = d.payload;
    },
    setShippingLastName(state, d) {
      state.shippingLastName = d.payload;
    },
    setShippingAdress(state, d) {
      state.shippingAdress = d.payload;
    },
    setShippingNumber(state, d) {
      state.shippingNumber = d.payload;
    },
    setShippingAdditional(state, d) {
      state.shippingAdditional = d.payload;
    },
    setShippingPostalCode(state, d) {
      state.shippingPostalCode = d.payload;
    },
    setShippingCity(state, d) {
      state.shippingCity = d.payload;
    },

    setCompany(state, d) {
      state.company = d.payload;
    },
    setTaxId(state, d) {
      state.taxId = d.payload;
    },

    setBillingIsShipping(state, d) {
      state.billingIsShipping = d.payload;
    },

    setBillingGender(state, d) {
      state.billingGender = d.payload;
    },
    setBillingFirstName(state, d) {
      state.billingFirstName = d.payload;
    },
    setBillingLastName(state, d) {
      state.billingLastName = d.payload;
    },
    setBillingAdress(state, d) {
      state.billingAdress = d.payload;
    },
    setBillingNumber(state, d) {
      state.billingNumber = d.payload;
    },
    setBillingAdditional(state, d) {
      state.billingAdditional = d.payload;
    },
    setBillingPostalCode(state, d) {
      state.billingPostalCode = d.payload;
    },
    setBillingCity(state, d) {
      state.billingCity = d.payload;
    },
  },
});

export const {
  setOrderState,
  setShippingGender,
  setShippingFirstName,
  setShippingLastName,
  setShippingAdress,
  setShippingNumber,
  setShippingAdditional,
  setShippingPostalCode,
  setShippingCity,
  setCompany,
  setTaxId,
  setBillingIsShipping,
  setBillingGender,
  setBillingFirstName,
  setBillingLastName,
  setBillingAdress,
  setBillingNumber,
  setBillingAdditional,
  setBillingPostalCode,
  setBillingCity
} = tpBookingSlice.actions;

export default tpBookingSlice.reducer;
