import { createSlice } from "@reduxjs/toolkit";

export const cookieSlice = createSlice({
    name: 'price',
    initialState: {
        acceptedCookieCategories: []
    },
    reducers: {
        setAcceptedCookieCategories(state, c) {
            state.acceptedCookieCategories = c.payload;
        },
    }
});

export const {setAcceptedCookieCategories} = cookieSlice.actions;

export default cookieSlice.reducer;