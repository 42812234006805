import React from 'react';
import { isMobile } from '../../App';
import { useTranslation } from 'react-i18next';

function Burger(props) {

    const { t } = useTranslation(['menu']);

    const onClick = e => {
        // Do something here I guess
        window.location.hash = "";
        window.location.hash = "#a-tourplanner";
    }

    const scrollHandler = (ev) => {
        var cta = document.getElementById("cta");
        if (cta === null) return;
        var welcomeImage = document.querySelector("#a-welcome > img");
        var tourPlannerImage = document.querySelector("#a-tourplanner > img");
        if (welcomeImage === null || tourPlannerImage === null) return;

        if ((welcomeImage.getBoundingClientRect().bottom - window.scrollY) < 1) { // Make Call-To-Action smaller when scrolling down :)
            if (!cta.classList.contains("small")) { // Check if it already has class assigned, just in case
                cta.classList.add("small");
            }
        } else if (cta.classList.contains("small")) {
            cta.classList.remove("small");
        }

        let tprect = tourPlannerImage.getBoundingClientRect();
        var phoneSymbol = document.getElementById("phoneSymbol");
        if (tprect.y < 300 && tprect.y + tprect.height > 0) {
            if (!cta.classList.contains("hidden")) {
                cta.classList.add("hidden");
                if (phoneSymbol !== null) {
                    phoneSymbol.classList.add("hide");
                    cta.children[0].ontransitionend = (e) => {
                        e.srcElement.ontransitionend = null;
                        cta.style.pointerEvents = "none";
                    }
                }
            }
        } else if (cta.classList.contains("hidden")) {
            cta.classList.remove("hidden");
            cta.style.pointerEvents = null;
            if (phoneSymbol !== null) {
                phoneSymbol.classList.remove("hide");
                phoneSymbol.style.display = null;
            }
        }
    };

    window.addEventListener("scroll", scrollHandler);
    
    return (
        <div className='call-to-action-hoverbox' id="cta">
            <div className="call-to-action" onClick={onClick}>
                <span className='icon'></span>
                <span className='short'>{t('project_genesis.menubar.plan_now_short')}</span>
                <span>{t('project_genesis.menubar.plan_now')}</span>
                {props.children}
            </div>
        </div>
    );

}

export default Burger;