import React, { useState } from 'react';
import { Container, Image } from "react-bootstrap";
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import FootLine from '../../component/FootLine';

import Burger from '../../component/subcomponent/Burger'
import MenuBar from '../../component/MenuBar';

// Style
import './blog.scss';

// Images
import bg from '../../assets/img/Impressum_Hintergrund_dunkel.png';
import magnifying_glass from '../../assets/img/icon/Lupe.svg';


function Blog(props) {

  const prefix = `${window.env.PUBLIC_URL}/blogs`;

  const { t } = useTranslation(['blog']);

  const cont = t('continue');

  const [list, loadList] = useState(null);
  const [search, updateSearch] = useState("");

  if (list === null) {
    fetch(`${prefix}/list.json`)
    .then((res) => {
      if (res.ok) {
        res.json()
        .then((data) => {
          loadList(data.articles.reverse());
        });
      } else {
        throw new Error("Failed to load successfully");
      }
    })
    .catch((e) => {
      console.error(e);
    })
  }

  return (
    <main>
      <I18nextProvider i18n={i18n}>
        <Burger subpage />
        <MenuBar subpage />
        <Image fluid src={bg} className='blog-bg'/>
        <Container fluid className='position-absolute top-0 left-0 ps-0 pe-0 blog-content-filler'>
          <h4 className='blog-headline'>{t('our_blog')}</h4>
          <div className={'blog-search-box' + ((search === "") ? " hide-input" : "")}>
            { search === "" && <div className='position-relative w-0 h-0'><div className='img-border'><img src={magnifying_glass}/></div></div> }
            <input type='text' value={search} onChange={(e) => updateSearch(e.target.value)} />
          </div>
          <div className='article-list'>
            {
              (list !== null) && Array.from(list, (a, i) => {
                  let lowersearch = search.toLowerCase();
                  let contains = a.headline.toLowerCase().indexOf(lowersearch) !== -1 || a.short.toLowerCase().indexOf(lowersearch) !== -1;
                  if (search === "" || contains) {
                    return (
                      <div className='item'>
                        <img src={`${prefix}/images/${a.res}.png`} />
                        <h1>{a.headline}</h1>
                        <p>{a.short}</p>
                        <a href={`/blog/${a.res}`}>{cont}</a>
                      </div>
                    );
                  }
                }
              )
            }
          </div>
          <FootLine />
        </Container>
      </I18nextProvider>
    </main>
  );
  
}

export default Blog;