import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/Store';
//Import Fonts
import './assets/fonts/DeLittle Chromatic_Bearbeitet.ttf'
import './assets/fonts/Proxima Nova Condensed Semibold.otf'
//Import Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
//Import Customs
import './assets/scss/app.scss'

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Blog from './pages/blog/blog';
import AGB from './pages/agb/agb';
import Imprint from './pages/imprint/imprint';
import DataProtection from './pages/dataprotection/dataprotection';
/*import { BrowserView, MobileView } from 'react-device-detect';
import MobileApp from './MobileApp';*/
import Article from './pages/blog/article';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes path='/'>
        <Route index element={
          <Provider store={store}>
            <App />
          </Provider>
        } />
        <Route path='blog' element={<Blog />} />
        <Route path='blog/:article' element={<Article article/>} />
        <Route path='agb' element={<AGB />} />
        <Route path='dataprotection' element={<DataProtection />} />
        <Route path='imprint' element={<Imprint />} />
      </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
