import React from 'react';
import { Container, Image } from "react-bootstrap";
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import FootLine from '../../component/FootLine';

import Burger from '../../component/subcomponent/Burger'
import MenuBar from '../../component/MenuBar';

// Style
import './imprint.scss';


// Images
import bg from '../../assets/img/Impressum_Hintergrund_dunkel.png';
import res_bg from '../../assets/img/responsive_backgrounds/Background6_Responsive.png';
import { isMobile } from 'react-device-detect';

function Imprint() {

    const { t } = useTranslation(['imprint']);

    return (
        <main>
          <I18nextProvider i18n={i18n}>
            <Burger subpage />
            <MenuBar subpage />
            <div className='setFootlineDown'>
              <Container fluid className='position-relative ps-0 pe-0'>
                <Image fluid src={isMobile ? res_bg : bg} className='imprint-bg'/>
                <div className='imprint-holder'>
                  <h1>{t('headline')}</h1>
                  <div className='three-pointer'>
                      <div>
                          <p>{t('referred.tag')}</p>
                          <p>{t('referred.name')}</p>
                          <p>{t('referred.street')}</p>
                          <p>{t('referred.city')}</p>
                      </div>
                      <div>
                          <p>{t('contact.tag')}</p>
                          <p>{t('contact.email')}</p>
                          <p>{t('contact.phone')}</p>
                          <br />
                          <p>{t('contact.owner_tag')}</p>
                          <p>{t('contact.owner')}</p>
                      </div>
                      <div>
                          <p>{t('registered.tag')}</p>
                          <p>{t('registered.entry')}</p>
                          <p>{t('registered.court')}</p>
                          <br />
                          <p>{t('registered.id-legal')}</p>
                          <p>{t('registered.ust-idnr')}</p>
                      </div>
                  </div>
                  <div className='text'>
                      <p>{t('usage')}</p>
                      <h2>{t('platform.tag')}</h2>
                      <p>{t('platform.a')}<a href={t('platform.link')} target='_blank'>{t('platform.link')}</a>{t('platform.b')}</p>
                      <p>{t('platform.c')}</p>
                      <h2>{t('consumer-dispute.tag')}</h2>
                      <p>{t('consumer-dispute.notice')}</p>
                      <p></p>
                      <h2>{t('disclaimer.tag')}</h2>
                      <p>{t('disclaimer.validation')}</p>
                      <p>{t('disclaimer.actuality')}</p>
                      <p>{t('disclaimer.ownership')}</p>
                  </div>
                </div>
              </Container>
              <FootLine />
            </div>
          </I18nextProvider>
        </main>
      );

}

export default Imprint;