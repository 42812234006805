import { createSlice } from "@reduxjs/toolkit";

function getValues(conf, id) {
    return conf.find(key => key.key == id);
}

const tourPlannerAPISlice = createSlice({
    name: 'api',
    initialState: {
        loaded: false,
        download_exclusive: undefined,
        group_size: undefined,
        group_size_num: -1,
        grades: undefined,
        duration: undefined,
        difficulty: undefined,
        preparation: undefined,
        topic: undefined,
        indoor: undefined,
        price_per_group: undefined,
        price_for_physical: undefined,
        digital_lead_time: undefined,
        physical_lead_time: undefined,
        recommended_tour_length: undefined,

        tour_loaded: false,
        tour_name: null,
        tour_created_date: null,
        tour_id: 999,
        tour_is_indoor: false,
        
        tour_presets: {},
        tour_steps: [],
        tour_alternative_tasks: []
    },
    reducers: {
        loadTourPlannerControllerData(state, data) {
            let arr = data.payload;
            for (let i = 0; i < arr.length; ++i) {
                let d = arr[i];
                let key = d.key;
                delete d.key;
                state[key] = d;
            }
            if (state.group_size.hasOwnProperty("possible_values")) {
                state.group_size_num = parseInt(state.group_size.possible_values[0].key);
            }
            if (state.topic.hasOwnProperty("possible_values")) {
                state.topic.possible_values = state.topic.possible_values.sort((a, b) => {
                    return a.id - b.id;
                });
            }
            if (state.grades.hasOwnProperty("possible_values")) {
                state.grades.possible_values = state.grades.possible_values.sort((a, b) => {
                    return parseInt(a.key) - parseInt(b.key);
                });
            }
            if (state.difficulty.hasOwnProperty("possible_values")) {
                state.difficulty.possible_values = state.difficulty.possible_values.sort((a, b) => {
                    return parseInt(a.key) - parseInt(b.key);
                });
            }
            state.loaded = true;
        },
        loadLoadedConfData(state, _data) {
            const data = _data.payload;
            state.download_exclusive = getValues(data, "download_exclusive");
            state.group_size = getValues(data, "group_size");
            state.grades.possible_values = [...getValues(data, "grades").possible_values].sort((a, b) => {
                return parseInt(a.key) - parseInt(b.key);
            });
            state.duration = getValues(data, "duration");
            state.preparation = getValues(data, "preparation");
            state.topic = getValues(data, "topic");
            state.indoor = getValues(data, "indoor");
            state.price_per_group = getValues(data, "price_per_group");
            state.price_for_physical = getValues(data, "price_for_physical");
            state.digital_lead_time = getValues(data, "digital_lead_time");
            state.physical_lead_time = getValues(data, "physical_lead_time");
            state.recommended_tour_length = getValues(data, "recommended_tour_length");
            state.difficulty = getValues(data, "difficulty");
            if (state.group_size.hasOwnProperty("possible_values")) {
                state.group_size_num = parseInt(state.group_size.possible_values[0].key);
            }
        },
        loadTourData(state, data) {
            let arr = data.payload;
            state.tour_name = arr.tour_name;
            state.tour_id = arr.id;
            state.tour_created_date = arr.tour_created_date;
            state.tour_is_indoor = arr.indoor;
            state.tour_presets = arr.presets;
            state.tour_steps = arr.toursteps;
            state.tour_alternative_tasks = arr.alternative_tasks;
            state.tour_loaded = true;
        },
        movedBack(state) {
            state.tour_loaded = false;
        }
    }
});

export const { loadTourPlannerControllerData, loadLoadedConfData, tourLoaded, loadTourData, movedBack } = tourPlannerAPISlice.actions;

export default tourPlannerAPISlice.reducer;