import { createSlice } from "@reduxjs/toolkit";

export const tpCategoriesSlice = createSlice({
    name: 'pickriddle',
    initialState: {
        loaded: false,
        tasks: [],
        steps: [],
        pickedTasks: [],
        hoveredTask: -1,
        errors: [],
        enlarged: false
    },
    reducers: {
        setTasks(state, tasks) {
            let ts = tasks.payload;
            /*let sort = [];
            for (let i = 0; i < ts.length; ++i) {
                let t = ts[i];
                while (sort.length < t.step) {
                    sort.push([]);
                }
                let j = t.step-1;
                sort[j].push(t);
            }
            state.steps = Array.from({length: sort.length}, (_) => 0);
            state.tasks = sort;*/
            ts.forEach(arr => arr.sort((a, b) => a.relative_difficulty - b.relative_difficulty));
            state.tasks = ts;
            state.steps = Array.from(ts, (t, i) => {
                let index = t.findIndex(task => task.relative_difficulty === 1);
                if (index !== -1) return index;
                index = t.findIndex(task => task.relative_difficulty === 0);
                if (index !== -1) return index;
                return 0;
            });
            state.loaded = true;
            
            if (state.tasks.length > 0) {
                let arr = [];
                for (let i = 0; i < state.tasks.length; ++i) {
                    let picked = state.tasks[i][state.steps[i]];
                    picked.step = i+1;
                    arr.push(picked);
                }
                state.pickedTasks = arr;
            }
        },
        setLoadedTourTasks(state, _tasks) {
            let tasks = _tasks.payload.tasks;
            let alt = _tasks.payload.alternative;
            console.log(tasks);
            let filteredTasks = tasks.filter(task => task.task !== null).sort((a, b) => a.step - b.step);
            let i = 0;
            state.tasks = filteredTasks.map(task => {
                let t = { ...task.task, step: ++i };
                t.relative_difficulty = t.difficulty;
                delete t.difficulty;
                return [t];
            });
            alt.forEach(task => {
                let pushtask = { ...task };
                //++pushtask.step;
                state.tasks[--task.step].push(pushtask);
            });
            state.tasks.forEach(arr => arr.sort((a, b) => a.relative_difficulty - b.relative_difficulty));
            state.steps = Array.from(state.tasks, (v, k) => {
                let pickedTask = filteredTasks[k];
                let index = v.findIndex(task => task.id === pickedTask.task.id);
                return index != -1 ? index : 0;
            });
            state.pickedTasks = Array.from(state.tasks, (v, k) => {
                return v[state.steps[k]];
            });
            state.loaded = true;
        },
        incStep(state, step) {
            if (state.steps[step.payload] < state.tasks[step.payload].length-1) {
                ++state.steps[step.payload];
            } else {
                state.steps[step.payload] = state.tasks[step.payload].length-1;
            }
            if (state.loaded && state.tasks.length > 0) {
                let arr = [];
                for (let i = 0; i < state.tasks.length; ++i) {
                    arr.push(state.tasks[i][state.steps[i]]);
                }
                state.pickedTasks = arr;
            }
        },
        decStep(state, step) {
            if (state.steps[step.payload] > 0) {
                --state.steps[step.payload];
            } else {
                state.steps[step.payload] = 0;
            }
            if (state.loaded && state.tasks.length > 0) {
                let arr = [];
                for (let i = 0; i < state.tasks.length; ++i) {
                    arr.push(state.tasks[i][state.steps[i]]);
                }
                state.pickedTasks = arr;
            }
        },
        setHoveredTask(state, index) {
            state.hoveredTask = index.payload;
        },
        addRPickRiddlesError(state, e){
            const error = e.payload;
            const index = state.errors.findIndex(e => e.id == error.id);
            const errorAlreadyExists = index > -1;
            if(!errorAlreadyExists){
                state.errors.push(error);
            }
        },
        removePickRiddlesError(state, i){
            const id = i.payload;
            state.errors = state.errors.filter(error => error.id != id);
        },
        unload(state) {
            state.loaded = false;
            state.tasks = [];
            state.steps = [];
            state.pickedTasks = [];
            state.hoveredTask = -1;
            state.errors = [];
            state.enlarged = false;
        },
        setEnlarged(state, b) {
            state.enlarged = b.payload;
        }
    }
});

export const {setTasks, setLoadedTourTasks, incStep, decStep, setHoveredTask, addRPickRiddlesError, removePickRiddlesError, unload, setEnlarged} = tpCategoriesSlice.actions;

export default tpCategoriesSlice.reducer;