import {ReactComponent as Pfeil} from '../../../assets/img/icon/Pfeil.svg';

function ArrowRight(props) {
    return (
        
            <Pfeil className={"getStartedArrow right" + (props.disabled ? " disabled" : "") } onClick={props.onClick} style={props.style}/>
    );

}

export default ArrowRight;