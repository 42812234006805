import { createSlice } from "@reduxjs/toolkit";

export const tpPriceSlice = createSlice({
    name: 'price',
    initialState: {
        pricePerGroupDigital: -1,
        pricePerGroupPhysical: -1,
    },
    reducers: {
        setPricePerGroupDigital(state, p) {
            state.pricePerGroupDigital = p.payload;
        },
        setPricePerGroupPhysical(state, p) {
            state.pricePerGroupPhysical = p.payload;
        },
    }
});

export const {setPricePerGroupDigital, setPricePerGroupPhysical} = tpPriceSlice.actions;

export default tpPriceSlice.reducer;