import { combineReducers, configureStore } from '@reduxjs/toolkit'
import TourPlannerSlice from './slices/TourPlannerSlice';
import TourPlannerCategoriesSlice from './slices/TourPlannerCategoriesSlice';
import TourPlannerPickRiddleSlice from './slices/TourPlannerPickRiddleSlice';
import TourPlannerPositionSlice from './slices/TourPlannerPositionSlice';
import TourPlannerReserveSlice from './slices/TourPlannerReserveSlice';
import TourPlannerPriceSlice from './slices/TourPlannerPriceSlice';
import TourPlannerAPISlice from './slices/TourPlannerAPISlice';
import TourPlannerOverviewSlice from './slices/TourPlannerOverviewSlice';
import TourPlannerBookingSlice from './slices/TourPlannerBookingSlice';
import ContactSlice from './slices/ContactSlice';
import AdventureShowcaseSlice from './slices/AdventureShowcaseSlice';
import CookieSlice from './slices/CookiesSlice';

const store = configureStore({
  reducer: combineReducers({
        tourPlanner: TourPlannerSlice,
        tourPlannerCategories: TourPlannerCategoriesSlice,
        tourPlannerPickRiddle: TourPlannerPickRiddleSlice,
        tourPlannerPosition: TourPlannerPositionSlice,
        tourPlannerReserve: TourPlannerReserveSlice,
        tourPlannerOverview: TourPlannerOverviewSlice,
        tourPlannerPrice: TourPlannerPriceSlice,
        tourPlannerAPI: TourPlannerAPISlice,
        tourPlannerBooking: TourPlannerBookingSlice,
        contact: ContactSlice,
        AdventureShowcase: AdventureShowcaseSlice,
        cookieSlice: CookieSlice,
        
    }),
})

export default store;