import React from 'react';
import { Container, Image } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import background from '../assets/img/Hintergrund6_16_9.png';
import minbackground from '../assets/img/minimized/Hintergrund6_16_9.png';
import starRating from '../assets/img/icon/star.svg';
import Headline from './subcomponent/Headline';

import ueber_uns from '../assets/img/Über uns.png';
import Section from './Section';

function About() {

    const { t } = useTranslation(['about']);

    return(
        <Section id="a-about" bg={background} minbg={minbackground}>
            <Headline text={t('project_genesis.about.headline')} />
            <div className='about-content-holder'>
                <span className='about-note'>{t('project_genesis.about.note')}</span>
                <Image loading="lazy" src={ueber_uns} className='about-img'/>
            </div>
            <div className='ratings-holder'>
                <div className='thirdPartyRatingDefaults thirdpartyrating1'>
                    <p>{t('project_genesis.about.bestrooms')}</p>
                    <p>{t('project_genesis.about.ratedbyescaperoomers')}</p>
                    <a href="https://www.escaperoomers.de">www.escaperoomers.de</a>
                </div>
                <div className='thirdPartyRatingDefaults thirdpartyrating2'>
                    <p>5,0<img src={starRating} alt="Star" /><img src={starRating} alt="Star" /><img src={starRating} alt="Star" /><img src={starRating} alt="Star" /><img src={starRating} alt="Star" /></p>
                    <p>{t('project_genesis.about.googlerated')}</p>
                </div>
                <div className='thirdPartyRatingDefaults thirdpartyrating3'>
                    <p>{t('project_genesis.about.bestescaperoom20202021')}</p>
                    <p>{t('project_genesis.about.ratedbyescapemaniacs')}</p>
                    <a href="https://www.escape-maniac.com">www.escape-maniac.com</a>
                </div>
            </div>
        </Section>
    );
}

export default About;