import React, { useEffect } from 'react';import { useTranslation } from 'react-i18next';
import res from '../assets/img/responsive_backgrounds/Background7_Responsive.png';
import min_res from '../assets/img/responsive_backgrounds/minimized/Background7_Responsive.png';
import bg from '../assets/img/Hintergrund7_16_9_darker.png';
import minbg from '../assets/img/minimized/Hintergrund7_16_9_darker.png';
import Headline from './subcomponent/Headline';
import { useDispatch, useSelector } from 'react-redux';
import Section from './Section';
import SideSroller from './subcomponent/SideScroller';
import { setContent } from '../redux/slices/AdventureShowcaseSlice';
import { toCoverLinks } from './subcomponent/GetStarted/AnimCard';
import { createArrays, options, step } from './subcomponent/GetStarted/Keyframer';
import { responsive } from '../App';

function AdventureShowcase() {

    const themes = [...useSelector((state) => state.tourPlannerCategories.themes)].sort((a, b) => (a.coming_soon === b.coming_soon) ? 0 : a.coming_soon ? 1 : -1);

    const { t } = useTranslation(['adventure_showcase']);
    const dispatch = useDispatch();

    const createDescription = (text) => {
        if (text === undefined) return null;
        if (text.startsWith("<!DOCTYPE html>")) {
            return (<div>
                <p>{t('project_genesis.loading_error')}</p>
            </div>);
        }
        if (text == "!SOON") {
            return (<div><h2>{t('project_genesis.wip_a')}</h2><h3>{t('project_genesis.wip_b')}</h3></div>);
        } else {
            var tokens = text.split("///");
            if (tokens.length == 2) {
                return (
                    <div>
                        <h1>{tokens[0]}</h1>
                        <p>{tokens[1]}</p>
                    </div>
                );
            }
        }
        return (
            <div>
                <p>{text}</p>
            </div>
        );
    };

    const Content = useSelector((state) => state.AdventureShowcase.content);

    useEffect(() => {
        Array.from(themes, (theme, k) => {
            if (Content[theme.key] === undefined) {
                fetch(`${window.env.PUBLIC_URL}/covers/${theme.key}.txt`).then((data) => data.text().then((text) => {
                    dispatch(setContent({
                        key: theme.key,
                        text: text
                    }));
                })).catch((e) => {
                    dispatch(setContent({
                        key: theme.key,
                        text: "text"
                    }));
                })
            }
        });
    }, [themes]);

    const makeKeyframes = (data, mul) => {
        let keyframes = [];
        let keyframe;
        let [pos, vel] = data;
        for (let i = 0; i < 100; ++i) {
            [keyframe, pos, vel] = step(pos, vel, mul);
            keyframes.push(keyframe);
        }
        data = [pos, vel];
        return keyframes;
    }

    const animate = (data, c, mul=1) => {
        let anim = c.animate(makeKeyframes(data, mul), options);
        anim.onfinish = () => {
            animate(data, c, mul);
        };
    };

    const createCard = (k, theme) => {
        const links = toCoverLinks(theme.key);
        return (
            <div style={{height:"50%"}} key={k}>
                <div className='animCard' ref={el => el !== null ? animate(createArrays(), el, 0.9) : null}>
                    <img className='animImage' alt="Poster" src={links[0]}/>
                    <img className='animOverlay' alt="Poster Overlay" src={links[1]}/>
                </div>
                <div id="showcase-text" className='showcase-text mobile'>
                    {createDescription(Content[theme.key])}
                </div>
            </div>
        )
    }

    return(
        <Section id="a-adventure-showcase" bg={responsive ? res : bg} minbg={responsive ? min_res : minbg}>
            <Headline classList="headline-text-shadow" text={t('project_genesis.headline')} />
            <SideSroller>
                {
                    Array.from(themes, (theme, k) => {
                        return createCard(k, theme);
                    })
                }
            </SideSroller>
        </Section>
    );
}

export default AdventureShowcase;