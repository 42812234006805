import React from 'react';

function AnimCard(props) {
    const classnames = (props.className !== undefined) ? ` ${props.className}` : "";
    if (props.theme !== null) {
        // If still loading.
        if (props.theme === undefined) {
            return(
                <div className={'animCard' + classnames} id={props.id}>
                    <p className='animImage loading'></p>
                </div>
            );
        }

        var links = toCoverLinks(props.theme.key);
        return(
            <div className={'animCard' + classnames} id={props.id}>
                <img className='animImage' alt="Poster" src={links[0]} onLoad={props.onLoad}/>
                <img className='animOverlay' alt="Poster Overlay" src={links[1]} />
            </div>
        );
    }
    return(
        <div className={'animCard' + classnames + " hidden"} id={props.id}>
            <img className='animImage' alt="Poster" src={""} />
            <img className='animOverlay' alt='Fallback'/>
        </div>
    );
    
}

export function toCoverLinks(id) {
    return [`${window.env.PUBLIC_URL}/covers/${id}.png`, `${window.env.PUBLIC_URL}/covers/${id}_over.png`];
}

export default AnimCard;