import res from '../assets/img/responsive_backgrounds/Background6_Responsive.png';
import min_res from '../assets/img/responsive_backgrounds/minimized/Background6_Responsive.png';
import bg from '../assets/img/Hintergrund6_16_9.png';
import minbg from '../assets/img/minimized/Hintergrund6_16_9.png';
import { useTranslation } from 'react-i18next';
import { Image } from "react-bootstrap";
import Headline from './subcomponent/Headline';
import ueber_uns from '../assets/img/Über uns.png';
import Section from './Section';
import star from '../assets/img/icon/star.svg';
import SideScroller from './subcomponent/SideScroller';
import { responsive } from '../App';

function MobileAbout() {

    const { t } = useTranslation(['about']);

    return(
        <Section id="a-about" bg={responsive ? res : bg} minbg={responsive ? min_res : minbg} className="d-flex flex-column">
            <Headline text={t('project_genesis.about.headline')} />
            <div className='about-container-mobile-stretch'>
                <div className='about-container-mobile'>
                    <Image loading="lazy" src={ueber_uns} className='about-img-mobile'/>
                    <p className='about-note-mobile'>{t('project_genesis.about.note')}</p>
                </div>
                <SideScroller classList="about-container-mobile scroller">
                    <div>
                        <h2>{t('project_genesis.about.bestrooms')}</h2>
                        <h1>{t('project_genesis.about.ratedbyescaperoomers')}</h1>
                        <a href="https://www.escaperoomers.de">www.escaperoomers.de</a>
                    </div>
                    <div>
                        <h2 className='rating'>5,0<img src={star} className="star"/><img src={star} className="star"/><img src={star} className="star"/><img src={star} className="star"/><img src={star} className="star"/></h2>
                        <h1>{t('project_genesis.about.googlerated')}</h1>
                    </div>
                    <div>
                        <h2>{t('project_genesis.about.bestescaperoom20202021')}</h2>
                        <h1>{t('project_genesis.about.ratedbyescapemaniacs')}</h1>
                        <a href="https://www.escape-maniac.com">www.escape-maniac.com</a>
                    </div>
                </SideScroller>
            </div>
        </Section>
    );

}

export default MobileAbout;