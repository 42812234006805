import Section from "../Section";
import minbackground from "../../assets/img/minimized/Hintergrund4_16_9.png";
import minres from "../../assets/img/responsive_backgrounds/minimized/Background4_Responsive.png";
import { responsive } from "../../App";

function LoadingTourPlannerSection() {
    return(
        <Section id="a-tourplanner" minbg={(responsive) ? minres : minbackground}>
            <p style={{textAlign: "center"}}>Loading...</p>
        </Section>
    );
}

export default LoadingTourPlannerSection;