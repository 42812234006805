import React from 'react';
import { useTranslation } from 'react-i18next';
import background from '../assets/img/Hintergrund2_16_9.png';
import minbackground from '../assets/img/Hintergrund2_16_9.png';
import res_bg from '../assets/img/responsive_backgrounds/Background2_Responsive.png';
import min_res_bg from '../assets/img/responsive_backgrounds/minimized/Background2_Responsive.png';
import ExploreShort from './subcomponent/ExploreShort';
import shortterm from '../assets/img/icon/Zeitunabhängig.png'
import individuel from '../assets/img/icon/Individuell.png'
import everywhere from '../assets/img/icon/Ortsunabhängig.png'
import custom from '../assets/img/icon/Einzigartig.png'
import Headline from './subcomponent/Headline';
import Section from './Section';
import { isMobile, responsive } from '../App';
import SideSroller from './subcomponent/SideScroller';


function Explore({ instant }) {

    const { t } = useTranslation(['welcome_explore']);

    window.addEventListener("scroll", ev => {
        var animDiv = document.getElementById("explore-shorts-container-anim");
        var ExploreSection = document.querySelector("#a-explore > img");
        if (ExploreSection !== null && animDiv !== null && (ExploreSection.getBoundingClientRect().bottom / 1.5 - window.scrollY) < 1) { // Make subjects appear when scrolling down
            if (!animDiv.classList.contains("explore-fade-in")) {
                animDiv.classList.add("explore-fade-in");
                instant.current = true;
            }
        }
    });

    return(
        <Section id="a-explore" bg={(responsive) ? res_bg : background} minbg={(responsive) ? min_res_bg : minbackground}>
            <Headline text={t('project_genesis.explore.headline')} classList="lower"/>
            {
                (isMobile) ? (
                    <div className='resp'>
                        <p className="explore-text text-break">{t('project_genesis.explore.text')}</p>
                        <SideSroller>
                            <ExploreShort className="mobile" icon={shortterm} title={t('project_genesis.explore.shorts.shortterm.title')} description={t('project_genesis.explore.shorts.shortterm.description')}/>
                            <ExploreShort className="mobile" icon={everywhere} title={t('project_genesis.explore.shorts.everywhere.title')} description={t('project_genesis.explore.shorts.everywhere.description')}/>
                            <ExploreShort className="mobile" icon={individuel} title={t('project_genesis.explore.shorts.individuel.title')} description={t('project_genesis.explore.shorts.individuel.description')}/>
                            <ExploreShort className="mobile" icon={custom} title={t('project_genesis.explore.shorts.custom.title')} description={t('project_genesis.explore.shorts.custom.description')}/>
                        </SideSroller>
                    </div>
                ) : (
                    <div>
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <p className="explore-text desktop text-break">{t('project_genesis.explore.text')}</p>
                        </div>
                        <div className={'explore-shorts-container' + ((instant.current) ? " explore-fade-instant" : "")} id='explore-shorts-container-anim'>
                            <ExploreShort className="shortterm-position" icon={shortterm} title={t('project_genesis.explore.shorts.shortterm.title')} description={t('project_genesis.explore.shorts.shortterm.description')}/>
                            <ExploreShort className="everywhere-position" icon={everywhere} title={t('project_genesis.explore.shorts.everywhere.title')} description={t('project_genesis.explore.shorts.everywhere.description')}/>
                            <ExploreShort className="individuel-position" icon={individuel} title={t('project_genesis.explore.shorts.individuel.title')} description={t('project_genesis.explore.shorts.individuel.description')}/>
                            <ExploreShort className="custom-position" icon={custom} title={t('project_genesis.explore.shorts.custom.title')} description={t('project_genesis.explore.shorts.custom.description')}/>
                        </div>
                    </div>
                )
            }
        </Section>
    );
}

export default Explore;