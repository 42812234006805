import { createSlice } from "@reduxjs/toolkit";

export function getIndex(values, currentValue) {
    return values.possible_values.findIndex(e => e.id == currentValue.id);
}

export function getValue(values, key) {
    return values.possible_values.find(e => e.key == key);
}

const tourPlannerSlice = createSlice({
    name: 'tour_planner',
    initialState: {
        planner_step: 0,
        tourcode: "",
        keycode: "",
        showingThemeIndex: 0,
        planingThemeIndex: 0,

        modal: {
            showModal: false,
            modalTitle: "",
            modalDescription: "",
            task: undefined
        }
    },
    reducers: {
        start(state) {
            state.planner_step = 1;
        },
        increment(state) {
            state.planner_step++;
        },
        decrement(state) {
            state.planner_step--;
        },
        setStep(state, index) {
            state.planner_step = Number.parseInt(index.payload);
        },
        setTourCode(state, code) {
            state.tourcode = code.payload;
        },
        setKeyCode(state, key) {
            state.keycode = key.payload;
        },
        setShowingThemeIndex(state, themeIndex) {
            state.showingThemeIndex = themeIndex.payload;
        },
        setPlaningThemeIndex(state, themeIndex) {
            state.planingThemeIndex = themeIndex.payload;
        },
        decThemeIndex(state) {
            if (state.showingThemeIndex > 0) {
                --state.showingThemeIndex;
            }
        },
        incThemeIndex(state, themesLength) {
            if (themesLength.payload-1 > state.showingThemeIndex) {
                ++state.showingThemeIndex;
            }
        },
        showModal(state, _data) {
            let data = _data.payload;
            state.modal.modalTitle = data.title;
            state.modal.modalDescription = data.desc;
            state.modal.modalTask = data.task;
            state.modal.showModal = true;
        },
        closeModal(state) {
            state.modal.showModal = false;
        }
    }
});

export const {start, increment, decrement, setStep, setTourCode, setKeyCode, setShowingThemeIndex, setPlaningThemeIndex, decThemeIndex, incThemeIndex, showModal, closeModal} = tourPlannerSlice.actions;

export default tourPlannerSlice.reducer;