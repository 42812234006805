import { createSlice } from "@reduxjs/toolkit";

export const AdventureShowcase = createSlice({
    name: 'adventureShowcase',
    initialState: {
        content: {}
    },
    reducers: {
        setContent(state, p) {
            state.content[p.payload.key] = p.payload.text;
        }
    }
});

export const { setContent } = AdventureShowcase.actions;

export default AdventureShowcase.reducer;