import React from 'react';
import { Container } from 'react-bootstrap';

function ExploreShort(props) {

    var classes = "";

    if (props.className !== undefined) {
        classes = " " + props.className;
    }

    return(
        <Container className={'explore-subject' + classes}>
            <img src={props.icon} className='icon'/>
            <p className="head">{props.title}</p>
            <p className="text">{props.description}</p>
        </Container>
    );
}

export default ExploreShort;