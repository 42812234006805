import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import background from '../assets/img/Hintergrund1_16_9.png';
import minbackground from '../assets/img/minimized/Hintergrund1_16_9.png';
import res_bg from '../assets/img/responsive_backgrounds/Background1_Responsive.png';
import min_res_bg from '../assets/img/responsive_backgrounds/minimized/Background1_Responsive.png';
import { isMobile, responsive } from '../App';
import logo from '../assets/img/logos/Logo.svg';
import Section from './Section';

export function hidemenu(e, force=false) {
    if (isMobile) {
        hidemenumobile(e, force);
        return;
    }
    var burgerSymbol = document.getElementById("burgerSymbol");
    var phoneSymbol = document.getElementById("phoneSymbol");
    var cta = document.getElementById("cta");
    var navBar = document.getElementById("navbar");
    if (force || (navBar.classList.contains("show") && navBar.getBoundingClientRect().width < e.clientX)) {
        if (cta && !cta.classList.contains("hidden")) {
            phoneSymbol.classList.remove("hide");
            phoneSymbol.style.display = null;
        }
        burgerSymbol.classList.remove("clicked");
        navBar.classList.remove("show");
    }
}

export function hidemenumobile(e, force=false) {
    var burgerSymbol = document.getElementById("burgerSymbol");
    var navBar = document.getElementById("navbar");
    if (force || (navBar.classList.contains("show") && navBar.getBoundingClientRect().width < e.clientX)) {
        burgerSymbol.classList.remove("clicked");
        navBar.classList.remove("show");
    }
}

export function hidemenusub(e, force=false) {
    var burgerSymbol = document.getElementById("burgerSymbol");
    var navBar = document.getElementById("navbar");
    if (force || (navBar.classList.contains("show") && navBar.getBoundingClientRect().width < e.clientX)) {
        burgerSymbol.classList.remove("clicked");
        navBar.classList.remove("show");
    }
}

function Welcome() {

    const { t } = useTranslation(['welcome_explore']);

    window.addEventListener("click", hidemenu);

    const hideYoAss = (e) => {
        var s = document.getElementById("phoneSymbol");
        if (s !== null && s.classList.contains("hide")) {
            s.style.display = "none";
        }
    };

    return(
        <Section id="a-welcome" bg={(responsive) ? res_bg : background} minbg={(responsive) ? min_res_bg : minbackground}>
            <object type="image/svg+xml" className='welcome-logo' data={logo}/>
            <div className='welcome-headline'>
                <div>
                    <span></span>
                    <h3 className='smaller-line'>{t('project_genesis.welcome.headline')}</h3>
                </div>
                <h1 className='smaller-line'>{t('project_genesis.welcome.big-headline-a')}</h1>
                <h2 className='smaller-line'>{t('project_genesis.welcome.big-headline-b')}</h2>
            </div>
            {
                !isMobile && (
                    <div className="phone-div" id="phoneSymbol" onClick={(ev) => {
                        if (!ev.target.classList.contains("hide")) {
                            document.location.href = "tel:" + t('project_genesis.welcome.phone_number');
                        }
                    }} onTransitionEnd={hideYoAss}>
                        <div className="phone"></div>
                        <span className="phone-tooltip">{t('project_genesis.welcome.phone_number')}</span>
                    </div>
                )
            }
        </Section>
    );
}

export default Welcome;