import { createSlice } from "@reduxjs/toolkit";

export const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        page: 0,
        first_name: "",
        last_name: "",
        email: "",
        likesIndex: {
            id: -1,
            text: ""
        },
        consider: ""
    },
    reducers: {
        setFirstName(state, firstname) {
            state.first_name = firstname.payload;
        },
        setLastName(state, lastname) {
            state.last_name = lastname.payload;
        },
        setEmail(state, email) {
            state.email = email.payload;
        },
        setPage(state, index) {
            state.page = index.payload;
        },
        setLikeIndex(state, likes) {
            state.likesIndex = likes.payload;
        },
        setConsider(state, consider) {
            state.consider = consider.payload;
        }
    }
});

export const { setFirstName, setLastName, setEmail, setPage, setLikeIndex, setConsider } = contactSlice.actions;

export default contactSlice.reducer;