import { Container } from "react-bootstrap";

function HowToMobileItem(props) {
    return (
        <Container fluid className='howto-bullet-mobile' id={`howtomobile-${props.number}`}>
            <div className='GPS'>
                <span>{props.number}</span>
            </div>
            <div className="content">
                <p className='title'>{props.title}</p>
                <p className='description'>{props.description}</p>
                <img src={props.gif} alt="Gif"/>
            </div>
        </Container>
    )
}

export default HowToMobileItem;