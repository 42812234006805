import React from 'react';
import { Container } from 'react-bootstrap';

function HowToShort(props) {

    function onhover(e) {
        var obj = document.getElementById(`b-${props.number}`);
        obj.classList.add("tmpShow");
        obj.style.display = null;
        var targetRect = e.target.getBoundingClientRect();
        var sourceRect = obj.getBoundingClientRect();
        obj.style.top = `calc(${targetRect.y - sourceRect.height / 2}px + 5%)`;
        if (obj.classList.contains("howtoreverseTooltip")) {
            obj.style.left = `${targetRect.x - sourceRect.width}px`;
        } else {
            obj.style.left = `calc(${targetRect.x}px + 3%)`;
        }
        /*obj.ontransitionstart = () => {
            obj.onanimationend = null;
            let img = obj.children[0].children[1];
            let text = obj.children[0].children[0].children[1];
            let fontSize = parseFloat(getComputedStyle(text).fontSize);
            let numberOfLines = Math.ceil(text.offsetHeight / fontSize);
        };*/
    }
    function onleave(e) {
        var obj = document.getElementById(`b-${props.number}`);
        obj.classList.remove("tmpShow");
    }

    if (props.number > 3) {
        return(
            <Container fluid className='howto-bullet' onMouseLeave={onleave}>
                <p className='title'>{props.title}</p>
                <p className='description'>{props.description}</p>
                <div className='GPS'>
                    <div onMouseOver={onhover}></div>
                    <span>{props.number}</span>
                </div>
            </Container>
        );
    }

    return(
        <Container fluid className='howto-bullet' onMouseLeave={onleave}>
            <p className='title'>{props.title}</p>
            <p className='description'>{props.description}</p>
            <div className='GPS'>
                <div onMouseOver={onhover}></div>
                <span>{props.number}</span>
            </div>
        </Container>
    );
}

export default HowToShort;